body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    overflow-x: hidden;
    /* background-color: #eefcee; */
  
  
  
  }
  
  *,
  *::before,
  *::after {
    box-sizing: inherit;
    margin: 0;
    border: none;
    outline: none;
  
  }
  
  ul {
    text-decoration: none;
    list-style: none;
    padding: 0;
  }
  
  /* :root {
    --primary: #013519;
    --semi_primary: #01461f;
    --dark_grey: #646464;
    --header_black: #1d1d1d;
  
    --Main_text: 58px;
  
    @media (max-width: 917px) {
  
      --Main_text: 52px;
    }
  
    @media (max-width: 757px) {
  
      --Main_text: 46px;
    }
    @media (max-width: 570px) {
  
      --Main_text: 30px;
    }
  
    @media (max-width: 370px) {
  
      --Main_text: 25px;
    }
  
    
  }
   */
  html {
    font-size: 16px;
  }
  
  :root {
    --ff:"Urbanist", sans-serif;
  
  }
  
  