

.maintenece {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #C4EDFF;


    .logo {
        
        right: 0px;
        // width: 180px;
        position: fixed;
        top: 26px;
        // background-color: #0081B8;
            @media( max-width: 447px) {
            width: 100px;
            // background-color: #0081B8;
            right: -30px;
            }
            @media( max-width: 400px) {
                width: 100px;
                // background-color: #0081B8;
                right: -40px;
                }

        .logo_warapper {
            width: 100px;
            // background-color: #1d5507;


            img {
                width: 59%;
                display: block;

                @media( max-width: 685px) {
                    width: 46%;
                    right: -180px;
                 }
                 
                @media( max-width: 400px) {
                    width: 37%;
                    
                 }
    
            }
        }
    }




   
    .content_wrapper {
        width: 800px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // background-color: #0081B8;
        gap: 13px;
        position: absolute;

        
        @media( max-width: 820px) {
            width: 70%;
        }

        @media( max-width: 789px) {
            gap: 9px;
        }
        @media( max-width: 611px) {
            width: 95%;
            
         }


        .img_wrapper {
            width: 250px;

            @media( max-width: 820px) {
                width: 200px;
            }
            
        @media( max-width: 685px) {
            width: 157px;
        }

            img {
                width: 100%;
                display: block;
           
            }
        }

        h5 {
            font-size:36px;
            color:#0081B8;
            font-weight: 600;

            
            @media( max-width: 820px) {
                font-size:32px;
            }

            @media( max-width: 789px) {
                font-size: 26px;
            }
                        
        @media( max-width: 685px) {
           font-size: 25px;
        }
        @media( max-width: 507px) {
                font-size: 22px;
            
         }
         @media( max-width: 427px) {
            font-size: 16px;
        
     }

            
        }
         span {
            font-size: 16px;
            font-weight: 400;
            color: #727272;
            width: 60%;

            @media( max-width: 789px) {
                font-size: 14px;
            }
            @media( max-width: 685px) {
                width: 84%;
             }
             @media( max-width: 611px) {
                width: 69%;
                
             }
             @media( max-width: 507px) {
              width: 80%;
            
         }
         @media( max-width: 427px) {
            font-size: 12px;
        
     }



         }

         .icon_number {

            // height: 80px;
            // width: 70px;
            display: flex;
            align-items: center;
            gap: 3px;
          

            svg {
                font-size: 28px;
                color: #000000;
                font-weight: 1000;

                @media( max-width: 427px) {
                    font-size: 19px;
                
             }


            }
            span {
                font-size: 17px;
                font-weight: 700;
                color: #000000;

                @media( max-width: 789px) {
                    font-size: 16px;
                }

                @media( max-width: 427px) {
                    font-size: 12px;
                
             }

             @media( max-width: 360px) {
               word-wrap: 83px;
            
         }

            }
         }
    }



}


